.ad-slider{
   position:fixed;
   top:1rem;
   left:0;
   width:100%;
}
.ad-slider span{
    position:absolute;

}
.ad-link img{
    width:100%;
    height: auto;
}
.ad-slider .slick-arrow{
display:none !important;
}
.ad-link{
    position:relative;
    display:block;
    z-index:1;
}
.ad-link span{
    position:absolute;
    top:2px;
    right:2px;

    color:#fff;
    padding:0.25rem 0.5rem;
    
    background-color:#000;

    font-size:0.5em;
}
@media (min-width: 768px){
    .ad-slider{
        position: fixed;
        top:1rem;
        left:1rem;
        width: 250px;
    }
}