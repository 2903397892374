.featured-post__image-container{
    width:100%;
    max-width:900px;
    position: relative;
}

.featured-post__image-container img{
    display:block;
    width:100%;
    height:100%;
    object-fit:cover;
}
 .featured-post__image-container span{
    position: absolute;
    bottom: 2px;
    left: 4px;
    font-size: 0.75em;
    opacity: 0.75;
    font-weight: lighter;
 }
.featured-post__wrapper{
    padding:1rem;
}
.featured-post__content-container p{
    white-space: pre-wrap;
}
.featured-post__content-container h2{
    color: #FF2E4C;

}